import * as React from 'react';
import Card from '@mui/material/Card';
import {Link as RouterLink} from 'react-router-dom';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Product} from "../store/useProducts";

interface ProductCardProps {
  product: Product;
}

export default function ProductCard(props: ProductCardProps) {
  const { product } = props;
  const [photo] = product.photos || [];

  return (
    <Card sx={{ maxWidth: "100%" }}>
      {photo && <CardMedia
        component="img"
        height="auto"
        image={photo.url}
        alt={photo.name}
      />}
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {product.title}
        </Typography>
        {/*<Typography variant="body2" color="text.secondary">*/}
        {/*  .....*/}
        {/*</Typography>*/}
      </CardContent>
      <CardActions>
        <RouterLink to={`/product/${product.id}`}>
          <Button size="small">Zobacz</Button>
        </RouterLink>
      </CardActions>
    </Card>
  );
}
