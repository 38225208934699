import { useMemo } from "react";
import { createBrowserHistory } from "history";
import cookies from "browser-cookies";

export default function useFeatureFlag(name: string): boolean {
  const history = useMemo(createBrowserHistory, []);
  const search = history.location.search;
  const params = extractParamsFromSearch(search);
  const featureKey = `feature.${name}`;

  if (params[featureKey] === "set") {
    cookies.set(featureKey, "1");
  } else if (params[featureKey] === "clear") {
    cookies.erase(featureKey);
  }

  return cookies.get(featureKey) === "1";
}

function extractParamsFromSearch(search: string): { [key: string]: string } {
  return search
    .slice(1)
    .split("&")
    .map(param => param.split("="))
    .reduce((acc, [key, value]) => Object.assign(acc, {
      [key]: value
    }), {});

}
